import * as i0 from '@angular/core';
import { InjectionToken, input, effect, forwardRef, ElementRef, Directive, Inject, HostListener, Pipe, output, Component, ViewChild, inject, NgZone, ContentChild, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { __decorate, __metadata } from 'tslib';
import * as i2 from '@angular/forms';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import IMask from 'imask';
import { isValidNumber, getExampleNumber, getCountries, getPhoneCode, AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import examples from 'libphonenumber-js/mobile/examples';
import { Subject, fromEvent, takeUntil, merge, tap } from 'rxjs';
import * as i1 from '@wlcm/angular/forms';
import { WlcmSelectComponent, WlcmFormsModule, WLCM_INPUT_BINDER } from '@wlcm/angular/forms';
import { WlcmOption } from '@wlcm/angular/core';
import { countries, getEmojiFlag, getCountryCode } from 'countries-list';
function WlcmCountryCodeSelectComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(2);
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(option_r2.emojiFlag);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r2.viewValue, "\n");
  }
}
function WlcmCountryCodeSelectComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "phoneCode");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const selected_r3 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" +", i0.ɵɵpipeBind1(2, 1, selected_r3 == null ? null : selected_r3.value), " ");
  }
}
const _c0 = [[["", "wlcmPhoneInput", ""]]];
const _c1 = ["[wlcmPhoneInput]"];
const WLCM_PHONE_ADAPTER = new InjectionToken('WLCM_PHONE_ADAPTER');
class WlcmPhoneAdapter {}
var WlcmPhoneValidationErrorType;
(function (WlcmPhoneValidationErrorType) {
  WlcmPhoneValidationErrorType["InvalidPhoneNumber"] = "InvalidPhoneNumber";
})(WlcmPhoneValidationErrorType || (WlcmPhoneValidationErrorType = {}));
let WlcmPhoneInputDirective = class WlcmPhoneInputDirective {
  handleBlur() {
    this._touched?.();
  }
  constructor(_elementRef, phoneAdapter) {
    this._elementRef = _elementRef;
    this.phoneAdapter = phoneAdapter;
    this.control = new FormControl();
    this.destroyInputObserver$ = new Subject();
    this.applyMask = input(true);
    effect(() => {
      if (this.applyMask()) {
        if (!this._imaskRef) this.initializeMask();
        this._imaskRef.mask = this.getMask();
        this._imaskRef.updateControl();
        this._imaskRef.on('accept', () => this.handleInput());
        this.destroyInputObserver$.next();
      } else {
        this.subscribeToInputEvent();
      }
    });
    this._updateMaskByCountryCode();
    this.subscribeToPasteEvent();
  }
  ngOnDestroy() {
    this.destroyInputObserver$.next();
    this.destroyInputObserver$.complete();
  }
  writeValue(value) {
    const parsedValue = this.phoneAdapter.parseValue(value);
    this.updateValue(parsedValue);
  }
  registerOnChange(callback) {
    this._changed = callback;
  }
  registerOnTouched(callback) {
    this._touched = callback;
  }
  registerOnValidatorChange(callback) {
    this._validatorChanged = callback;
  }
  validate(control) {
    const isValid = isValidNumber(control.value ?? '');
    if (!isValid) {
      return {
        [WlcmPhoneValidationErrorType.InvalidPhoneNumber]: true
      };
    }
    return null;
  }
  setDisabledState(isDisabled) {
    if (isDisabled) {
      return this.control.disable();
    }
    this.control.enable();
  }
  _updateControl() {
    this.handleInput();
  }
  _updateMaskByCountryCode() {
    const code = this.phoneAdapter.getCurrentCountryCode();
    const nationalFormat = getExampleNumber(code, examples)?.formatNational();
    const mask = this.getMask(nationalFormat);
    if (this._imaskRef) {
      this._imaskRef.mask = mask;
      this._imaskRef.updateControl();
    }
    this.element.placeholder = mask;
  }
  get element() {
    return this._elementRef.nativeElement;
  }
  getMask(nationalFormat) {
    if (!nationalFormat) return '(000) 000-0000';
    return nationalFormat.replace(/\d/g, '0');
  }
  updateValue(parsedValue) {
    if (this._imaskRef) {
      this._imaskRef.value = parsedValue;
      this._imaskRef.updateControl();
    } else {
      this.element.value = parsedValue;
    }
  }
  handleInput() {
    const value = this.element.value;
    this._changed?.(this.phoneAdapter.formatValue(value));
  }
  handlePaste(event) {
    event.preventDefault();
    const value = event.clipboardData?.getData('text') ?? '';
    const parsedValue = this.phoneAdapter.parseValue(value);
    this._updateMaskByCountryCode();
    this.updateValue(parsedValue);
    this.handleInput();
  }
  initializeMask() {
    this._imaskRef = IMask(this.element, {
      mask: Number
    });
  }
  subscribeToInputEvent() {
    fromEvent(this.element, 'input').pipe(takeUntil(this.destroyInputObserver$)).subscribe(() => this.handleInput());
  }
  subscribeToPasteEvent() {
    fromEvent(this.element, 'paste').pipe(untilDestroyed(this)).subscribe(event => this.handlePaste(event));
  }
  static {
    this.ɵfac = function WlcmPhoneInputDirective_Factory(t) {
      return new (t || WlcmPhoneInputDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(WLCM_PHONE_ADAPTER));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: WlcmPhoneInputDirective,
      selectors: [["", "wlcmPhoneInput", ""]],
      hostAttrs: [1, "wlcm-form-field-input"],
      hostBindings: function WlcmPhoneInputDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("blur", function WlcmPhoneInputDirective_blur_HostBindingHandler($event) {
            return ctx.handleBlur($event);
          });
        }
      },
      inputs: {
        applyMask: [i0.ɵɵInputFlags.SignalBased, "applyMask"]
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmPhoneInputDirective),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmPhoneInputDirective),
        multi: true
      }])]
    });
  }
};
WlcmPhoneInputDirective = __decorate([UntilDestroy(), __metadata("design:paramtypes", [ElementRef, WlcmPhoneAdapter])], WlcmPhoneInputDirective);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmPhoneInputDirective, [{
    type: Directive,
    args: [{
      selector: '[wlcmPhoneInput]',
      host: {
        class: 'wlcm-form-field-input'
      },
      standalone: true,
      providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => WlcmPhoneInputDirective),
        multi: true
      }, {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => WlcmPhoneInputDirective),
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: WlcmPhoneAdapter,
    decorators: [{
      type: Inject,
      args: [WLCM_PHONE_ADAPTER]
    }]
  }], {
    handleBlur: [{
      type: HostListener,
      args: ['blur', ['$event']]
    }]
  });
})();
class WlcmCountryCodeOption extends WlcmOption {
  constructor(value, viewValue, emojiFlag) {
    super(value, viewValue);
    this.value = value;
    this.viewValue = viewValue;
    this.emojiFlag = emojiFlag;
  }
}
const COUNTRIES_LIST = getCountries().filter(code => countries.hasOwnProperty(code)).map(code => ({
  ...countries[code],
  code
})).sort((a, b) => a.name.localeCompare(b.name));
const WLCM_COUNTRY_CODE_OPTIONS = new InjectionToken('WLCM_COUNTRY_CODE_OPTIONS', {
  providedIn: 'root',
  factory: () => {
    return COUNTRIES_LIST.map(country => {
      const phone = getPhoneCode(country.code);
      const viewValue = `${country.name} +${phone}`;
      const emojiFlag = getEmojiFlag(getCountryCode(country.name));
      return new WlcmCountryCodeOption(getCountryCode(country.name), viewValue, emojiFlag);
    });
  }
});
class PhoneCodePipe {
  transform(value) {
    return value ? getPhoneCode(value) : '1';
  }
  static {
    this.ɵfac = function PhoneCodePipe_Factory(t) {
      return new (t || PhoneCodePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "phoneCode",
      type: PhoneCodePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PhoneCodePipe, [{
    type: Pipe,
    args: [{
      name: 'phoneCode',
      standalone: true
    }]
  }], null, null);
})();
class WlcmCountryCodeSelectComponent {
  constructor(options) {
    this.options = options;
    this.changed = output();
    this.control = input(new FormControl('US'));
  }
  get selectComponent() {
    return this.wlcmSelect.matSelect;
  }
  static {
    this.ɵfac = function WlcmCountryCodeSelectComponent_Factory(t) {
      return new (t || WlcmCountryCodeSelectComponent)(i0.ɵɵdirectiveInject(WLCM_COUNTRY_CODE_OPTIONS));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmCountryCodeSelectComponent,
      selectors: [["wlcm-country-code-select"]],
      viewQuery: function WlcmCountryCodeSelectComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(WlcmSelectComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.wlcmSelect = _t.first);
        }
      },
      hostAttrs: [1, "wlcm-country-code-select"],
      inputs: {
        control: [i0.ɵɵInputFlags.SignalBased, "control"]
      },
      outputs: {
        changed: "changed"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 4,
      consts: [["optionTemplate", ""], ["triggerTemplate", ""], [3, "selectionChange", "options", "formControl", "optionTemplate", "triggerTemplate"], [1, "emoji-flag"], [1, "wlcm-country-code-select-trigger"]],
      template: function WlcmCountryCodeSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "wlcm-select", 2);
          i0.ɵɵlistener("selectionChange", function WlcmCountryCodeSelectComponent_Template_wlcm_select_selectionChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.changed.emit($event));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(1, WlcmCountryCodeSelectComponent_ng_template_1_Template, 3, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(3, WlcmCountryCodeSelectComponent_ng_template_3_Template, 3, 3, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const optionTemplate_r4 = i0.ɵɵreference(2);
          const triggerTemplate_r5 = i0.ɵɵreference(4);
          i0.ɵɵproperty("options", ctx.options)("formControl", ctx.control())("optionTemplate", optionTemplate_r4)("triggerTemplate", triggerTemplate_r5);
        }
      },
      dependencies: [CommonModule, WlcmFormsModule, i1.WlcmSelectComponent, ReactiveFormsModule, i2.NgControlStatus, i2.FormControlDirective, PhoneCodePipe],
      styles: [".emoji-flag[_ngcontent-%COMP%]{font-size:20px;vertical-align:bottom;margin-right:8px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmCountryCodeSelectComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-country-code-select',
      host: {
        class: 'wlcm-country-code-select'
      },
      standalone: true,
      imports: [CommonModule, WlcmFormsModule, ReactiveFormsModule, PhoneCodePipe],
      template: "<wlcm-select\n  [options]=\"options\"\n  [formControl]=\"control()\"\n  [optionTemplate]=\"optionTemplate\"\n  [triggerTemplate]=\"triggerTemplate\"\n  (selectionChange)=\"changed.emit($event)\"\n></wlcm-select>\n\n<ng-template #optionTemplate let-option>\n  <span class=\"emoji-flag\">{{ option.emojiFlag }}</span>\n\n  {{ option.viewValue }}\n</ng-template>\n\n<ng-template #triggerTemplate let-selected>\n  <div class=\"wlcm-country-code-select-trigger\">\n    +{{ selected?.value | phoneCode }}\n  </div>\n</ng-template>\n",
      styles: [".emoji-flag{font-size:20px;vertical-align:bottom;margin-right:8px}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WLCM_COUNTRY_CODE_OPTIONS]
    }]
  }], {
    wlcmSelect: [{
      type: ViewChild,
      args: [WlcmSelectComponent]
    }]
  });
})();
let WlcmPhoneInputContainerComponent = class WlcmPhoneInputContainerComponent {
  constructor(_zone) {
    this._zone = _zone;
    this._focusStream$ = new Subject();
    this._blurStream$ = new Subject();
    this.focus$ = this._focusStream$.asObservable();
    this.blur$ = this._blurStream$.asObservable();
    this.control = new FormControl('US');
    this._asYouType = new AsYouType('US');
    this.parseValue = value => {
      if (!value || value.length === 0) return '';
      if (isValidNumber(value)) {
        const result = parsePhoneNumber(value, 'US');
        if (result.country) this.control.setValue(result.country);
        return result.nationalNumber;
      }
      return value;
    };
    this.formatValue = value => {
      this._asYouType.reset();
      this._asYouType.input(`+${this.currentCallingCode}${value}`);
      const countryCode = this._asYouType.getCountry();
      if (countryCode) this.control.setValue(countryCode);
      return this._asYouType.getNumber()?.number ?? '';
    };
  }
  ngAfterContentInit() {
    if (!this.phoneInput) {
      throw new Error('The [wlcmPhoneInput] directive must be provided.');
    }
    this.handleInputEvents();
  }
  isFocused() {
    return this.phoneInput.element === document.activeElement || this.codeSelect?.selectComponent.panelOpen;
  }
  focus() {
    this.phoneInput.element.focus();
  }
  getCurrentCountryCode() {
    return this.control.value;
  }
  handleCountryChange() {
    this.phoneInput._updateControl();
    this.phoneInput._updateMaskByCountryCode();
  }
  get currentCallingCode() {
    return getPhoneCode(this.control.value);
  }
  handleInputEvents() {
    this._zone.runOutsideAngular(() => {
      merge(fromEvent(this.phoneInput.element, 'focus').pipe(tap(() => this._focusStream$.next())), fromEvent(this.phoneInput.element, 'blur').pipe(tap(() => this._blurStream$.next()))).pipe(untilDestroyed(this)).subscribe();
    });
  }
  static {
    this.ɵfac = function WlcmPhoneInputContainerComponent_Factory(t) {
      return new (t || WlcmPhoneInputContainerComponent)(i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmPhoneInputContainerComponent,
      selectors: [["wlcm-phone-input-container"]],
      contentQueries: function WlcmPhoneInputContainerComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, WlcmPhoneInputDirective, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.phoneInput = _t.first);
        }
      },
      viewQuery: function WlcmPhoneInputContainerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(WlcmCountryCodeSelectComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.codeSelect = _t.first);
        }
      },
      hostAttrs: [1, "wlcm-phone-input-container"],
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const container = inject(WlcmPhoneInputContainerComponent, {
            self: true
          });
          return {
            bind: () => container
          };
        }
      }, {
        provide: WLCM_PHONE_ADAPTER,
        useExisting: forwardRef(() => WlcmPhoneInputContainerComponent)
      }]), i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 1,
      consts: [[3, "changed", "click", "control"], [1, "wlcm-phone-input-container-divider"]],
      template: function WlcmPhoneInputContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "wlcm-country-code-select", 0);
          i0.ɵɵlistener("changed", function WlcmPhoneInputContainerComponent_Template_wlcm_country_code_select_changed_0_listener() {
            return ctx.handleCountryChange();
          })("click", function WlcmPhoneInputContainerComponent_Template_wlcm_country_code_select_click_0_listener($event) {
            return $event.stopPropagation();
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelement(1, "div", 1);
          i0.ɵɵprojection(2);
        }
        if (rf & 2) {
          i0.ɵɵproperty("control", ctx.control);
        }
      },
      dependencies: [CommonModule, WlcmCountryCodeSelectComponent]
    });
  }
};
WlcmPhoneInputContainerComponent = __decorate([UntilDestroy(), __metadata("design:paramtypes", [NgZone])], WlcmPhoneInputContainerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmPhoneInputContainerComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-phone-input-container',
      host: {
        class: 'wlcm-phone-input-container'
      },
      standalone: true,
      imports: [CommonModule, WlcmCountryCodeSelectComponent],
      providers: [{
        provide: WLCM_INPUT_BINDER,
        useFactory: () => {
          const container = inject(WlcmPhoneInputContainerComponent, {
            self: true
          });
          return {
            bind: () => container
          };
        }
      }, {
        provide: WLCM_PHONE_ADAPTER,
        useExisting: forwardRef(() => WlcmPhoneInputContainerComponent)
      }],
      template: "<wlcm-country-code-select\n  [control]=\"control\"\n  (changed)=\"handleCountryChange()\"\n  (click)=\"$event.stopPropagation()\"\n></wlcm-country-code-select>\n\n<div class=\"wlcm-phone-input-container-divider\"></div>\n\n<ng-content select=\"[wlcmPhoneInput]\"></ng-content>\n"
    }]
  }], () => [{
    type: i0.NgZone
  }], {
    codeSelect: [{
      type: ViewChild,
      args: [WlcmCountryCodeSelectComponent]
    }],
    phoneInput: [{
      type: ContentChild,
      args: [WlcmPhoneInputDirective]
    }]
  });
})();
const publicComponents = [WlcmPhoneInputContainerComponent];
const publicDirectives = [WlcmPhoneInputDirective];
class WlcmPhoneInputModule {
  static {
    this.ɵfac = function WlcmPhoneInputModule_Factory(t) {
      return new (t || WlcmPhoneInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WlcmPhoneInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, publicComponents]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmPhoneInputModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ...publicComponents, ...publicDirectives],
      exports: [...publicComponents, ...publicDirectives]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { WlcmPhoneInputContainerComponent, WlcmPhoneInputDirective, WlcmPhoneInputModule, WlcmPhoneValidationErrorType };
